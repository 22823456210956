<template>
  <g
    ><g>
      <g> <rect x="10.8" y="38.8" class="st0" width="10.7" height="17.2" /> </g>
      <g> <rect x="26.9" y="30.8" class="st0" width="10.7" height="25.1" /> </g>
      <g> <rect x="43" y="25.2" class="st0" width="10.7" height="30.8" /> </g>
      <g>
        <path
          class="st1"
          d="M11.5,29.5c-0.4,0-0.9-0.2-1.1-0.6c-0.4-0.6-0.2-1.4,0.4-1.8l15.1-9.9c0.6-0.4,1.3-0.3,1.8,0.3l3.9,4.8
		l12.1-7.4l-2.8-0.5c-0.6-0.1-1.1-0.7-1.1-1.3c0-0.1,0-0.1,0-0.2c0.1-0.7,0.8-1.2,1.5-1.1l6.3,1.1c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0.2,0,0.5,0.2,0.6,0.3v0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0.1,0.1,0.1,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0.1,0.2,0.3,0.4,0.3,0.6l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6L46.7,21c-0.2,0.7-1,1.1-1.7,0.9c-0.7-0.2-1.1-1-0.9-1.7l0.9-3l-13.2,8
		c-0.6,0.3-1.3,0.2-1.7-0.3l-3.8-4.8l-14,9.3C12,29.5,11.8,29.5,11.5,29.5z"
        />
      </g>
    </g>
    <g>
      <path
        class="st1"
        d="M32.2,74.2c-0.2,0-0.3,0-0.5-0.1L0.5,56C0.2,55.8,0,55.5,0,55.1V19c0-0.4,0.2-0.7,0.5-0.9l31.2-18c0.3-0.2,0.7-0.2,1,0l31.2,18c0.3,0.2,0.5,0.5,0.5,0.9v36.1c0,0.4-0.2,0.7-0.5,0.9L32.7,74C32.6,74.1,32.4,74.2,32.2,74.2zM2,54.5L32.2,72l30.2-17.5V19.6L32.2,2.2L2,19.6V54.5z"
      />
    </g>
  </g>
</template>

<script>
export default {};
</script>


<style scoped>
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #d96338;
}
.st1 {
  fill: #d96338;
}
/*viewBox="0 0 64.5 74.2"*/
</style>