<template>
  <g>
    <g>
      <path
        class="st0"
        d="M7.7,33.8c4.2,0,7.7,3.4,7.7,7.7c0,4.2-3.4,7.7-7.7,7.7c-4.2,0-7.7-3.4-7.7-7.7C0,37.3,3.4,33.8,7.7,33.8"
      />
    </g>
    <g>
      <path
        d="M33.9,30.6c-8.4,0-15.3-6.9-15.3-15.3S25.5,0,33.9,0c8.4,0,15.3,6.9,15.3,15.3S42.3,30.6,33.9,30.6z M33.9,3
			c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3c6.8,0,12.3-5.5,12.3-12.3C46.2,8.5,40.7,3,33.9,3z"
      />
    </g>
    <g>
      <path
        d="M2.1,40.3c-0.3,0-0.6-0.1-0.8-0.2c-0.7-0.5-0.9-1.4-0.4-2.1L20.4,8.2c0.5-0.7,1.4-0.9,2.1-0.4c0.7,0.5,0.9,1.4,0.4,2.1
			L3.3,39.6C3.1,40,2.6,40.3,2.1,40.3z"
      />
    </g>
    <g>
      <path
        d="M10.7,48.5c-0.5,0-1-0.2-1.3-0.7c-0.5-0.7-0.3-1.6,0.4-2.1l31-20.3c0.7-0.5,1.6-0.3,2.1,0.4c0.5,0.7,0.3,1.6-0.4,2.1
			l-31,20.3C11.3,48.4,11,48.5,10.7,48.5z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M33.9,7.5c4.3,0,7.7,3.5,7.7,7.7c0,4.3-3.5,7.7-7.7,7.7c-4.3,0-7.7-3.5-7.7-7.7C26.2,11,29.6,7.5,33.9,7.5"
      />
    </g>
  </g>
</template>

<script>
export default {};
</script>

<style>
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
/*  width="49.2px" height="49.2px"  */
</style>