<template>
  <Section class="flex w-full flex-col">
    <!-- start first card -->
    <div
      v-for="user in state.users.data"
      :key="user.id"
      class="py-2 mx-4 mb-1 bg-opacity-90 px-3 border-2 bg-navy border-orange"
    >
      <div class="row">
        <!--1. Picture-->
        <div class="col-md-1 justify-center flex self-center">
          <img
            :src="user.avatar"
            :alt="user.first_name"
            class="rounded-full border-orange border-2"
          />
        </div>
        <!--1. Meta data-->
        <div class="col-md-11 ">
          <div class="row flex">
            <div class="col-sm-8 col-md-9">
              <h2 class="text-white text-lg">
                {{ user.last_name }} , {{ user.first_name }}
              </h2>
              <div class="row">
                <!--MOS-->
                <div class="col-md-4">
                  <h2
                    class="text-md text-white pb-0 mb-0 w-100"
                    style="font-weight: 400"
                  >
                    {{ user.last_name }}
                  </h2>
                  <div class="hidden md:block py-2 self-center text-sm">
                    <p class="text-white text-left py-0 mb-0">
                      Last Save: {{ user.first_name }}
                    </p>
                  </div>
                </div>
                <!--rank-->
                <div class="col-md-4">
                  <h2 class="text-md text-white w-100" style="font-weight: 400">
                    {{ user.id }}
                  </h2>
                </div>
                <!-- Unit  -->
                <div class="col-md-4">
                  <h2 class="text-md text-white w-100" style="font-weight: 400">
                    {{ user.email }}
                  </h2>
                </div>
              </div>
            </div>
            <!--button-->
            <div class="col-md-3 mt-2 text-right right-0">
              <button
                type="button"
                class="
                  btn btn-primary
                  text-end text-xl
                  md:w-auto
                  max-w-2xl
                  transition-all
                  text-center
                  hover:
                  shadow-2xl
                "
              >
                START
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12  text-sm text-white text-left font-weight-400">
          <span class="font-bold">Recomended Next Action:</span>
          {{ user.last_name }}
        </div>
      </div>
      <!-- progess bar-->
      <div class="row px-3 pb-2">
        <div class="col-md-12 relative">
          <div class="row">
            <p class="text-opacity-90 text-sm text-left pt-1 text-white">
              <span class="font-bold mr-3">
                Armify Recomendations Progress:
              </span>
              <span class="">{{ user.email }}</span>
            </p>
          </div>
          <div class="mt-2 progress" style="max-width: 100%; height: 8px">
            <div
              class="progress-bar"
              style="width: 83%; background-color: #64f1fa"
            ></div>
            <div
              class="progress-bar bg-warning progress-bar-stripped"
              style="width: 17%; background-color: #d142b8"
            ></div>
          </div>
          <a
            class="min-w-full block"
            onclick="toggleText('menu1')"
            style="cursor: pointer"
          >
            <img
              id="menu1-img"
              class="absolute right-0 top-0 h-6 block"
              src="../assets/images/addBtn.png"
            />
          </a>
        </div>
      </div>
      <!--slide down chart -->
      <div id="menu1" class="chartHide" style="display: none">
        <div class="row">
          <div class="col-md-11 pr-0" style="position: relative">
            <img class="pt-1 w-100 pl-3" src="../assets/images/chartBg.png" />
            <div
              id="chartContainer"
              class="pl-3 pr-5"
              style="height: 122px; width: 99%; position: absolute; top: 0px"
            ></div>
          </div>
        </div>

        <div
          id="carouselContent"
          class="carousel slide ml-0 pl-0 pr-5 mr-5"
          data-ride="carousel"
        >
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active text-center p-4">
              <p class="text-white">{{ user.email }}</p>
            </div>
            <div class="carousel-item text-center p-4">
              <p class="text-white">{{ user.last_name }}</p>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselContent"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselContent"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
    <!-- end first card -->
  </Section>
</template>



<script>
import { onMounted, reactive } from "vue";
import axios from "../plugins/axios.js";

export default {
  setup() {
    const state = reactive({
      users: {},
      total: {},
    });

    onMounted(async () => {
      const { data } = await axios.get(`/users`);
      state.users = data;
    });

    async function prev() {
      const { data } = await axios.get("/users?page=1");
      state.users = data;
    }
    async function next() {
      const { data } = await axios.get("/users?page=2");
      state.users = data;
    }

    return { state, next, prev };
  },
};
</script>

<style>
</style>