<template>
  <div
    class="h-screen overflow-x-hidden"
    :style="{ 'margin-left': sidebarWidth }"
  >
    <router-view />
    <Sidebar />
  </div>
</template>

<script>
// import FilterBar from "./components/FilterBar.vue";
import Sidebar from "./components/sidebar/Sidebar.vue";
import { sidebarWidth } from "@/components/sidebar/state";
export default {
  setup() {
    return { sidebarWidth };
  },

  components: { Sidebar },
  data() {
    return {
      product: "Socks",
    };
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/* #nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>

