<template>
  <div class="relative">
    <Bullet class="overflow-y-scroll overscroll-contain text-base" />

    <Backgrounds />
  </div>
</template>

<script>
// import Tab from "../components/Tab.vue";
import Backgrounds from "../components/Backgrounds.vue";
import Bullet from "../components/Bullet.vue";
export default {
  components: {
    Bullet,
    Backgrounds,
    // Tab,
  },
  data() {
    return {
      drag: false,
    };
  },
};
</script>

<style>
.active {
  color: white !important;
  background-color: rgba(17, 22, 36, var(--tw-bg-opacity)) !important;
}
.nav-link {
  color: white !important;
}

.bottom-glow {
  /* position: absolute; */
  /* bottom: calc(50% - 20px); */

  width: 100%;
  height: 5px;
  opacity: 0.8;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 -15px 10px 5px #fff, 0 -30px 30px 20px #db9151,
    0 10px 25px #c46818;
}
</style>