
<template>
  <div class="sidebar" :style="{ width: sidebarWidth }">
    <div class="text-lg my-4">
      <span v-if="collapsed">
        <div>A</div>
      </span>
      <span v-else>Armify</span>
    </div>

    <!-- home -->
    <SidebarLink
      v-if="home"
      v-model="home"
      class="
        text-base
        mb-2
        border-white border-t-1 border-r-2 border-b-2 border-t-0
      "
      to="/"
      icon="fas fa-home"
    >
      <template v-slot:icon>
        <IconBase icon-name="Autogenerate" viewBox="0 0 97.3 75">
          <Autogenerate />
        </IconBase>
      </template>
      <template v-slot:main> Home </template>
    </SidebarLink>

    <!-- soldier list  -->
    <SidebarLink
      v-if="showSoldierList"
      v-model="showSoldierList"
      class="
        text-base
        mb-2
        border-white border-t-1 border-r-2 border-b-2 border-t-0
      "
      to="/Soldier-List"
      icon="fas fa-home"
    >
      <template v-slot:icon>
        <IconBase icon-name="ListingPage" viewBox="0 0 97.3 75">
          <ListingPage />
        </IconBase>
      </template>
      <template v-slot:main> Soldier List </template>
    </SidebarLink>

    <!-- soldier dashboard -->
    <SidebarLink
      v-if="showDashboard"
      v-model="showDashboard"
      class="
        text-base
        mb-2
        border-white border-t-1 border-r-2 border-b-2 border-t-0
      "
      to="/soldier-detail"
      icon="fas fa-home"
    >
      <template v-slot:icon>
        <IconBase icon-name="Autogenerate" viewBox="0 0 97.3 75">
          <Autogenerate />
        </IconBase>
      </template>
      <template v-slot:main> Soldier Dashboard</template></SidebarLink
    >

    <!-- Statement  list  -->
    <SidebarLink
      v-if="showStatementList"
      class="
        text-base
        mb-2
        border-white border-t-1 border-r-2 border-b-2 border-t-0
      "
      to="/statement-list"
      icon="fas fa-home"
    >
      <template v-slot:icon>
        <IconBase icon-name="Autogenerate" viewBox="0 0 97.3 75">
          <Autogenerate />
        </IconBase>
      </template>
      <template v-slot:main> Statement List </template></SidebarLink
    >

    <!-- Statement Detail -->
    <SidebarLink
      v-if="showStatementDetail"
      class="
        text-base
        mb-2
        border-white border-t-1 border-r-2 border-b-2 border-t-0
      "
      to="/statement-detail"
      icon="fas fa-home"
    >
      <template v-slot:icon>
        <IconBase icon-name="BulletPointsDetails" viewBox="0 0 50.7 50.7">
          <BulletPointsDetails />
        </IconBase>
      </template>
      <template v-slot:main> Statement Detail </template>
    </SidebarLink>

    <!-- Statement Custom -->
    <SidebarLink
      v-if="showStatementCustom"
      class="
        text-base
        mb-2
        border-white border-t-1 border-r-2 border-b-2 border-t-0
      "
      to="/statement-Custom"
      icon="fas fa-home"
    >
      <template v-slot:icon>
        <IconBase icon-name="Autogenerate" viewBox="0 0 97.3 75">
          <Autogenerate />
        </IconBase>
      </template>
      <template v-slot:main> Statement Custom </template>
    </SidebarLink>

    <div v-if="showDev">
      <h1>ShowDev</h1>

      <!-- Tabs -->
      <SidebarLink
        class="
          text-base
          mb-2
          border-white border-t-1 border-r-2 border-b-2 border-t-0
        "
        to="/Tabs"
        icon="fas fa-home"
      >
        <template v-slot:icon>
          <IconBase icon-name="Autogenerate" viewBox="0 0 97.3 75">
            <Autogenerate />
          </IconBase>
        </template>
        <template v-slot:main> Tabs </template></SidebarLink
      >

      <SidebarLink
        class="
          text-base
          mb-2
          border-white border-t-1 border-r-2 border-b-2 border-t-0
        "
        to="/Handle"
        icon="fas fa-home"
        ><template v-slot:icon>
          <IconBase icon-name="Autogenerate" viewBox="0 0 97.3 75">
            <Autogenerate />
          </IconBase>
        </template>
        <template v-slot:main> Handle </template>
      </SidebarLink>

      <SidebarLink
        class="
          text-base
          mb-2
          border-white border-t-1 border-r-2 border-b-2 border-t-0
        "
        to="/Export"
        icon="fas fa-home"
        ><template v-slot:icon>
          <IconBase icon-name="Autogenerate" viewBox="0 0 97.3 75">
            <Autogenerate />
          </IconBase>
        </template>
        <template v-slot:main> Export </template></SidebarLink
      >
      <SidebarLink
        class="
          text-base
          mb-2
          border-white border-t-1 border-r-2 border-b-2 border-t-0
        "
        to="/ExpandableBar"
        icon="fas fa-home"
        ><template v-slot:icon>
          <IconBase
            icon-name="Autogenerate"
            v-bind:size="size"
            viewBox="0 0 97.3 75"
            :height="height"
            :width="width"
          >
            <Autogenerate />
          </IconBase>
        </template>
        <template v-slot:main> ExpandableBar </template></SidebarLink
      >
    </div>

    <span
      class="collapse-icon bg-na"
      :class="{ 'rotate-180': collapsed }"
      @click="toggleSidebar"
    >
      <i class="fas fa-angle-double-left" />
    </span>
  </div>
</template>

<style>
:root {
  --sidebar-bg-color: #111624;
  --sidebar-item-hover: #35446e;
  --sidebar-item-active: #0d6efd;
}
</style>

<style scoped>
.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);

  float: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5em;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
}

.sidebar h1 {
  height: 2.5em;
}

.collapse-icon {
  position: absolute;
  top: 0;
  padding: 0.75em;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
</style>
<script>
import SidebarLink from "./SidebarLink";
import IconBase from "../../assets/images/svgs/IconBase.vue";
import Autogenerate from "../../assets/images/svgs/Autogenerate.vue";
import ListingPage from "../../assets/images/svgs/ListingPage.vue";
// import BulletPointsDetails from "../../assets/images/svgs/BulletPointsDetails.vue";

import { collapsed, toggleSidebar, sidebarWidth } from "./state";

export default {
  props: {},
  components: {
    SidebarLink,
    IconBase,
    Autogenerate,
    ListingPage,
    // ListingPage,
  },
  setup() {
    return { collapsed, toggleSidebar, sidebarWidth };
  },
  data() {
    return {
      height: 100,
      width: 100,
      home: true,
      showSoldierList: true,
      showStatementList: true,
      showStatementCustom: true,
      showStatementDetail: true,
      showDashboard: true,
      showDev: false,
    };
  },
};
</script>
