<template>
  <div style="" class="carosalDiv border">
    <!-- <div class="container"> -->
    <div class="cards-wrapper w-10" style="z-index: 1">
      <div class="border w-full">
        <button @click="shiftLeft()" title="left arrow">
          <i class="fa fa-align-justify hover:text-white">left arrow</i>
        </button>
      </div>
      <ul class="cards__container border">
        <li
          class="box border p-4"
          v-for="(page, index) in AvalaibleNavbarPages"
          :key="`AvalaibleNavbarPages-${index}`"
        >
          <div class="card">
            <h2 class="cardHeading text-center">
              {{ page.pageName }}
              <img src="https://via.placeholder.com/300.png/09f/fff" alt="" />
            </h2>
            {{ page.pageUrl }}
          </div>
        </li>

        <li class="box box--hide">
          <div class="card">
            <h2 class="cardHeading text-center">
              pagepageName1
              <img src="https://via.placeholder.com/300.png/09f/fff" alt="" />
            </h2>
            pagepageUrl1
          </div>
        </li>
        <li class="box box--hide">
          <div class="card">
            <h2 class="cardHeading text-center">
              pagepageName2
              <img src="https://via.placeholder.com/300.png/09f/fff" alt="" />
            </h2>
            pagepageUrl2
          </div>
        </li>
      </ul>
      <div>
        <button @click="shiftRight()" title="reorder - Manual">
          <i class="fa fa-align-justify hover:text-white">Right</i>
        </button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      AvalaibleNavbarPages: [
        { pageName: "Autogenerate", pageUrl: "/link-to new path" },
        { pageName: "Custom", pageUrl: "/link-to new path" },
        { pageName: "Compare", pageUrl: "/compare-to-peers" },
        { pageName: "Stats", pageUrl: "/soldier-stats" },
        { pageName: "Event", pageUrl: "/event" },
      ],
    };
  },

  methods: {
    shiftRight: function () {
      const boxes = document.querySelectorAll(".box");
      boxes[4].className = "box move-out-from-right";
      setTimeout(function () {
        const noOfCards = boxes.length;
        if (noOfCards > 4) {
          boxes[4].className = "box box--hide";
        }

        const tmpNode = boxes[noOfCards - 1];
        tmpNode.classList.remove("box--hide");
        boxes[noOfCards - 1].remove();
        let parentObj = document.querySelector(".cards__container");
        parentObj.insertBefore(tmpNode, parentObj.firstChild);
        tmpNode.className = "box move-to-position1-from-right";
        boxes[0].className = "box move-to-position2-from-right";
        boxes[1].className = "box move-to-position3-from-right";
        boxes[2].className = "box move-to-position4-from-right";
        boxes[3].className = "box move-to-position5-from-right";
      }, 500);
    },
  },
  shiftLeft: function () {
    const boxes = document.querySelectorAll(".box");
    boxes[4].className = "box move-out-from-right";
    setTimeout(function () {
      const noOfCards = boxes.length;
      if (noOfCards > 4) {
        boxes[4].className = "box box--hide";
      }

      const tmpNode = boxes[noOfCards - 1];
      tmpNode.classList.remove("box--hide");
      boxes[noOfCards - 1].remove();
      let parentObj = document.querySelector(".cards__container");
      parentObj.insertBefore(tmpNode, parentObj.firstChild);
      tmpNode.className = "box move-to-position1-from-right";
      boxes[0].className = "box move-to-position2-from-right";
      boxes[1].className = "box move-to-position3-from-right";
      boxes[2].className = "box move-to-position4-from-right";
      boxes[3].className = "box move-to-position5-from-right";
    }, 500);
  },
};
</script>


<style scoped>
html {
  font-size: 16px;
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Lato", sans-serif;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button {
  margin-left: 0 3%;
  width: 2rem;
  cursor: pointer;
}

.button--inactive {
  opacity: 0.2;
}

.button img {
  width: 60%;
}

.cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cards__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25rem;
}

.box {
  /*     margin: -1.5rem; */
  width: 14rem;
  height: 20rem;
  box-shadow: 0px 0px 2rem 0px #888888;
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* transition: 1s all; */
}

.box:nth-child(2n) {
  transform: scale(0.85);
  z-index: -1;
}

.box:nth-child(2) {
  left: 5%;
}

.box:nth-child(4) {
  left: -5%;
}

.box:nth-child(4n + 1) {
  transform: scale(0.75);
  z-index: -2;
}

.box:nth-child(1) {
  left: 15%;
}

.box:nth-child(5) {
  left: -15%;
}

.card__text-content {
  text-align: center;
  width: 75%;
}

.card__title {
  padding: 1rem;
}

.box--hide {
  display: none;
}

.move-out-from-left {
  animation: moveOutLeft 0.5s ease-in-out;
}

.move-out-from-right {
  animation: moveOutRight 0.5s ease-in-out;
}

.move-to-position5-from-left {
  animation: moveToP5Left 0.5s ease-in-out;
}

.move-to-position4-from-left {
  animation: moveToP4Left 0.5s ease-in-out;
}

.move-to-position3-from-left {
  animation: moveToP3Left 0.5s ease-in-out;
}

.move-to-position2-from-left {
  animation: moveToP2Left 0.5s ease-in-out;
}

.move-to-position1-from-left {
  animation: moveToP1Left 0.5s ease-in-out;
}

.move-to-position5-from-right {
  animation: moveToP5Right 0.5s ease-in-out;
}
.move-to-position4-from-right {
  animation: moveToP4Right 0.5s ease-in-out;
}
.move-to-position3-from-right {
  animation: moveToP3Right 0.5s ease-in-out;
}
.move-to-position2-from-right {
  animation: moveToP2Right 0.5s ease-in-out;
}
.move-to-position1-from-right {
  animation: moveToP1Right 0.5s ease-in-out;
}

@keyframes moveOutLeft {
  0% {
    transform: scale(0.75) translateX(0%);
    opacity: 1;
  }
  50% {
    transform: scale(0.5) translateX(-150%);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.25) translateX(0%);
    opacity: 0;
  }
}

@keyframes moveOutRight {
  0% {
    transform: scale(0.75) translateX(0%);
    opacity: 1;
  }
  50% {
    transform: scale(0.5) translateX(150%);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.25) translateX(0%);
    opacity: 0;
  }
}

@keyframes moveToP5Left {
  from {
    transform: scale(0.75) translateX(100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@keyframes moveToP4Left {
  from {
    transform: scale(0.75) translateX(100%);
  }
  to {
    transform: scale(0.85) translateX(0);
  }
}

@keyframes moveToP3Left {
  from {
    transform: scale(0.85) translateX(100%);
  }
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes moveToP2Left {
  from {
    transform: scale(1) translateX(100%);
  }
  to {
    transform: scale(0.85) translateX(0);
  }
}

@keyframes moveToP1Left {
  from {
    transform: scale(0.85) translateX(100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@keyframes moveToP1Right {
  from {
    transform: scale(0.75) translateX(-100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@keyframes moveToP2Right {
  from {
    transform: scale(0.75) translateX(-100%);
  }
  to {
    transform: scale(0.85) translateX(0);
  }
}

@keyframes moveToP3Right {
  from {
    transform: scale(0.85) translateX(-100%);
  }
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes moveToP4Right {
  from {
    transform: scale(1) translateX(-100%);
  }
  to {
    transform: scale(0.85) translateX(0);
  }
}

@keyframes moveToP5Right {
  from {
    transform: scale(0.85) translateX(-100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

/* Card
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.card {
  display: flex;
  flex-direction: column;
  background: var(--white);
  width: 300px;
  padding: 0px 25px;
  /* border-radius: 20px; */
  background-color: #252525;
  border: 2px solid white;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.13);
}
.cardHeading {
  text-shadow: #00b4b3 1px 0 10px;
  color: #00b4b3;
  font-size: 25px;
  font-weight: 500;
}

/* Radio Button
–––––––––––––––––––––––––––––––––––––––––––––––––– */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #00b4b3;
  border-radius: 100%;
  background: #00b4b3;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #252525;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* radio end here */

/* carosal style start here */
.box {
  /* box-shadow: 0px 0px 2rem 0px #888888; */
  box-shadow: none !important;
}
.move-to-position1-from-right {
  animation: moveToP1Right 0.5s ease-in-out;
  margin-top: -110px;
  opacity: 0.5;
}
.move-to-position5-from-right {
  animation: moveToP1Right 0.5s ease-in-out;
  margin-top: -110px;
  opacity: 0.5;
}
.move-to-position3-from-right {
  animation: moveToP1Right 0.5s ease-in-out;
  margin-top: 110px;
}
.move-to-position1-from-left {
  animation: moveToP1Left 0.5s ease-in-out;
  margin-top: -110px;
  opacity: 0.5;
}
.move-to-position5-from-left {
  animation: moveToP1Left 0.5s ease-in-out;
  margin-top: -110px;
  opacity: 0.5;
}
.move-to-position3-from-left {
  animation: moveToP1Left 0.5s ease-in-out;
  margin-top: 110px;
}
/* carosal style end here */
.carosalDiv {
  position: relative;
  top: 0px;
  display: flex;
  justify-content: center;
}
.footerCardz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: scale(0.8);
  flex-wrap: wrap;
}
.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
#navBtn {
  transition: 0.5s;
}

.sidebar a {
  padding: 8px 8px 8px 0px;
  text-decoration: none;
  font-size: 25px;
  /* color: #818181; */
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

/* .sidebar .closebtn {
  position: absolute;
  top: 11px;
  width: 36px;
  right: 13px;
  margin-left: 5px;
} */

.openbtn {
  font-size: 20px;
  cursor: pointer;
  /* background-color: #111; */
  color: white;
  padding: 10px 10px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}
#main {
  transition: margin-left 0.5s;
  padding: 0px 0;
  padding-left: 50px;
  margin-left: 50px;
  min-height: 100vh;
}
.container {
  width: 75%;
  margin: 20px auto;
}
.customLegend li {
  display: inline-block;
  margin-right: 25px;
  margin-top: 15px;
}
.customLegend li span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 25px;
}
.cardOuterDiv {
  position: relative;
  bottom: 30px;
  margin-top: 70px;
}
@media (max-width: 900px) {
  #main {
    padding-left: 0 !important;
    margin-left: 0px !important;
  }
}

@media (max-width: 767px) {
  /* #main{
      padding-left: 0;
      margin-left: 30px!important;
    } */
  /* .imgRightPadding{
      padding-right: 0;
    } */
  .modificationBtn {
    padding-right: 0.5rem;
  }
  .carosalDiv {
    transform: scale(0.6);
  }
}
@media (max-width: 505px) {
  .footerCardz {
    justify-content: center;
    padding-left: 20px;
  }
  .cardOuterDiv {
    margin-top: 0px;
  }
}
/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}
</style>