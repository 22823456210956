<template>
  <g>
    <g>
      <path
        class="st0"
        d="M37.1,62.1c-0.3,0.3-0.6,0.6-1,0.9c-1,0.8-2.2,1.2-3.6,1.2c-1.2,0-2.4-0.2-3.6-0.5c-2.1-0.7-3.6-2.1-4.4-4.1
			c-0.1-0.2-0.2-0.2-0.3-0.3c-1.5-0.1-2.9-0.5-4.2-1.4c-1.5-1-2.5-2.3-2.8-4c-0.2-1.3-0.1-2.6,0.4-3.9c0.1-0.2,0.1-0.4,0.2-0.6
			c-0.1,0-0.1-0.1-0.2-0.1c-1.5-0.7-2.8-1.7-3.8-3.1c-1-1.4-1.6-3-1.5-4.8c0.1-1.6,0.6-3.1,1.6-4.3c0.3-0.3,0.3-0.6,0.1-1
			c-0.6-1.3-1-2.6-1.4-4c-0.4-1.8-0.5-3.5-0.3-5.4c0.3-2,0.9-3.9,1.9-5.6c1.1-1.9,2.6-3.4,4.6-4.4c0.6-0.3,1.2-0.4,1.8-0.6
			c0.1,0,0.2-0.1,0.2-0.1c0.7-1.5,1.7-2.7,3.1-3.6c1.5-1.1,3.3-1.7,5.1-2.1c1.4-0.3,2.8-0.4,4.2-0.3c1.3,0.1,2.4,0.4,3.3,1.4
			c0.1,0.1,0.2,0.3,0.4,0.4c0.2-0.2,0.4-0.4,0.6-0.6c0.7-0.6,1.6-1,2.5-1.1c1.1-0.2,2.2-0.1,3.3,0c1.2,0.1,2.3,0.4,3.4,0.9
			c2,0.8,3.5,2.1,4.6,3.9c0.2,0.3,0.4,0.7,0.5,1.1c0,0.1,0.1,0.2,0.2,0.2c2.6,0.6,4.7,1.9,6.4,3.9c1.7,2,2.7,4.4,3.1,7
			c0.3,2.1,0.1,4.1-0.6,6c-0.4,1.2-0.9,2.4-1.4,3.6c-0.1,0.1-0.1,0.2,0,0.3c0.5,0.5,0.9,1.1,1.3,1.7c0.5,0.9,0.7,1.8,0.8,2.8
			c0,1.2-0.1,2.3-0.4,3.5c-0.5,1.6-1.5,2.9-2.9,3.8c-0.3,0.2-0.7,0.4-1.1,0.6c0.1,0.2,0.2,0.5,0.3,0.8c0.9,2.3,0.6,4.4-0.8,6.4
			c-0.8,1.2-2,1.8-3.3,2.3c-1.3,0.4-2.5,0.6-3.9,0.6c-0.2,0-0.2,0.1-0.3,0.2c-0.6,1.3-1.4,2.5-2.7,3.3c-0.9,0.6-1.9,0.9-2.9,1.1
			c-0.8,0.1-1.5,0.2-2.3,0.2C39.8,64.3,38.3,63.6,37.1,62.1C37.1,62.2,37.1,62.1,37.1,62.1z M35.9,28.8c0-0.1,0-0.2,0-0.2
			c0-1.6,0-3.2,0-4.8c0-2.3,0-4.5,0-6.8c0-1-0.1-2.1-0.4-3.1c-0.3-1-0.9-1.5-2-1.6c-1.3-0.1-2.7,0-4,0.3c-1.7,0.4-3.3,1-4.7,2.2
			c-1,0.8-1.8,1.8-2.1,3.1c0,0.2-0.2,0.2-0.3,0.3c-0.6,0-1.1,0.1-1.6,0.3c-1.3,0.5-2.4,1.3-3.2,2.3c-1.6,1.9-2.5,4.1-2.8,6.5
			c-0.1,1.2-0.1,2.4,0.1,3.5c0.2,1.4,0.6,2.6,1.2,3.9c0,0,0.1,0,0.1,0c1.5,0,3,0,4.6,0c0.1,0,0.2-0.1,0.2-0.2
			c0.6-1.5,2.1-2.4,3.6-2.2c2.6,0.3,4,3.2,2.6,5.4c-0.8,1.2-1.9,1.8-3.3,1.6c-1.4-0.1-2.3-0.9-2.9-2.2c0-0.1-0.1-0.1-0.2-0.1
			c-1.2,0-2.4,0-3.6,0c-0.1,0-0.2,0-0.2,0.1c-0.3,0.3-0.6,0.6-0.9,1c-1.4,1.7-1.7,3.6-0.9,5.7c0.5,1.5,1.7,2.5,3,3.2
			c0.8,0.5,1.7,0.7,2.6,1c0.2,0.1,0.3,0.1,0.5,0.2c-0.1,0.2-0.3,0.4-0.4,0.6c-0.4,0.7-0.8,1.4-1.1,2.1c-0.5,1.5-0.4,2.9,0.7,4.2
			c1,1.1,2.3,1.7,3.8,1.9c0.6,0.1,1.3,0.1,1.9,0.1c0.1,0,0.2,0,0.2,0.1c0,0.2,0.1,0.4,0.2,0.6c0.3,1.5,1.2,2.6,2.6,3.3
			c1.3,0.6,2.7,0.7,4.1,0.7c0.7,0,1.3-0.3,1.8-0.7c0.7-0.6,1-1.5,1-2.4c0-2.6,0-5.3,0-7.9c0-2.5,0-5.1,0-7.6c0-0.1,0-0.1,0-0.2
			c-2,0-3.9,0-5.9,0c0,0,0,0.1,0,0.1c0,1.6,0,3.1,0,4.7c0,0.1,0.1,0.2,0.2,0.2c1.6,0.7,2.4,2.2,2.2,3.9c-0.3,1.7-1.9,3-3.7,2.9
			c-1.9-0.1-3.3-1.6-3.3-3.5c0-1.4,0.8-2.7,2.2-3.3c0.1-0.1,0.2-0.1,0.2-0.3c0-2.3,0-4.6,0-6.9c0-0.1,0-0.2,0-0.3c2.8,0,5.5,0,8.3,0
			c0-3.2,0-6.3,0-9.4c-2,0-3.9,0-5.9,0c0-2,0-3.9,0-5.9c-0.8,0-1.6,0-2.5,0c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.6-0.6,1-1.1,1.4
			c-1.2,0.9-2.7,1.1-4,0.3c-1.3-0.8-1.9-2.2-1.7-3.7c0.3-1.4,1.5-2.8,3.2-2.9c1.4-0.1,2.9,0.7,3.5,2.2c0.1,0.1,0.1,0.2,0.3,0.2
			c1.5,0,3,0,4.5,0c0.1,0,0.1,0,0.2,0c0,2,0,3.9,0,5.9C33.5,28.8,34.7,28.8,35.9,28.8z M38.3,25.3c0,8.3,0,16.5,0,24.8
			c0,0,0.1,0,0.1,0c1.6,0,3.1,0,4.7,0c0.1,0,0.2-0.1,0.2-0.1c0.6-1.3,1.5-2.1,3-2.2c2-0.2,3.8,1.4,3.8,3.4c0.1,2-1.5,3.6-3.5,3.7
			c-1.4,0-2.7-0.8-3.3-2.2c-0.1-0.1-0.1-0.2-0.3-0.2c-1.5,0-3,0-4.6,0c-0.1,0-0.1,0-0.2,0c0,0.1,0,0.2,0,0.2c0,1.3,0,2.7,0,4
			c0,0.8,0,1.6,0,2.4c0.1,1.5,1.2,2.7,2.8,2.8c1.2,0.1,2.3-0.1,3.5-0.4c1.6-0.5,2.8-1.6,3.2-3.2c0.1-0.3,0.2-0.7,0.3-1
			c0.1,0,0.2,0,0.2,0c1.1,0,2.1,0,3.1-0.2c1-0.2,1.9-0.5,2.8-1c1.5-0.9,2-2.5,1.7-4c-0.1-0.8-0.5-1.5-0.8-2.2
			c-0.2-0.4-0.3-0.7-0.3-1.2c0-1.4,0-2.8,0-4.2c0-0.1,0-0.1,0-0.2c-3.6,0-7.1,0-10.6,0c0-2.1,0-4.1,0-6.1c-0.1,0-0.2-0.1-0.2-0.1
			c-1.4-0.6-2.3-2.2-2.1-3.7c0.3-2,2-3.3,4-3.1c1.6,0.2,3,1.7,3,3.3c0.1,1.5-0.8,2.9-2.2,3.5c-0.1,0.1-0.2,0.1-0.2,0.3
			c0,1.1,0,2.3,0,3.4c0,0.1,0,0.1,0,0.2c3.6,0,7.1,0,10.6,0c0,1.7,0,3.5,0,5.2c0.1,0,0.1,0,0.1-0.1c0.8-0.5,1.5-1.3,1.8-2.2
			c0.4-1.1,0.5-2.2,0.4-3.4c0-1-0.4-1.8-1.1-2.5c-0.4-0.5-0.9-0.9-1.3-1.4c-0.1-0.1-0.1-0.2-0.1-0.3c0.7-1.5,1.4-3,1.9-4.6
			c0.5-1.7,0.8-3.5,0.5-5.3c-0.4-2.8-1.7-5.1-3.8-7c-1-0.9-2.2-1.5-3.6-1.9c-0.4-0.1-0.9-0.2-1.4-0.2c-0.2,0-0.3-0.1-0.4-0.3
			c-0.1-0.4-0.2-0.9-0.4-1.3c-0.8-1.8-2.2-2.9-3.9-3.5c-1.7-0.6-3.5-0.8-5.3-0.7c-0.9,0.1-1.5,0.5-1.8,1.3c-0.3,0.8-0.4,1.7-0.5,2.6
			c0,2.1,0,4.3,0,6.4c0,0.1,0,0.1,0,0.2c0.1,0,0.2,0,0.3,0c3,0,6.1,0,9.1,0c0.2,0,0.3-0.1,0.4-0.3c0.6-1.4,2.2-2.3,3.7-2.1
			c2,0.3,3.3,2.2,3,4.2c-0.3,1.7-1.8,2.9-3.5,2.9c-1.5,0-2.6-0.8-3.2-2.2c-0.1-0.1-0.1-0.2-0.3-0.2c-3.1,0-6.1,0-9.2,0
			C38.4,25.3,38.4,25.3,38.3,25.3z M25.3,35.9c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2
			C24.8,37.1,25.3,36.6,25.3,35.9z M28.8,52.4c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2
			C27.6,51.9,28.1,52.4,28.8,52.4z M51.3,22.9c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.1,1.2c0.7,0,1.2-0.5,1.2-1.2
			C52.4,23.4,51.9,22.9,51.3,22.9z M47.7,51.3c0-0.7-0.5-1.2-1.1-1.2c-0.7,0-1.2,0.5-1.2,1.1c0,0.7,0.5,1.2,1.1,1.2
			C47.2,52.4,47.7,51.9,47.7,51.3z M24.1,22.9c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2
			C25.3,23.4,24.7,22.9,24.1,22.9z M45.4,35.9c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2
			C44.2,35.4,44.7,35.9,45.4,35.9z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M52.4,74.2H21.7L0,52.4V21.7L21.7,0h30.7l21.7,21.7v30.7L52.4,74.2z M22.5,72.2h29.1l20.5-20.5V22.5L51.6,2
			H22.5L2,22.5v29.1L22.5,72.2z"
      />
    </g>
  </g>
</template>

<script>
export default {};
</script>

<style scoped>
.st0 {
  fill: #00b0f0;
}
/* viewBox="0 0 74.2 74.2" */
</style>

