<template>
  <g>
    <g>
      <g>
        <g>
          <path
            class="st0"
            d="M52.3,59.5H19.5c-4,0-7.2-3.2-7.2-7.2V23.6c0-4,3.2-7.2,7.2-7.2h4.4c0.8,0,1.4,0.6,1.4,1.4
					s-0.6,1.4-1.4,1.4h-4.4c-2.4,0-4.4,2-4.4,4.4v28.7c0,2.4,2,4.4,4.4,4.4h32.8c2.4,0,4.4-2,4.4-4.4V23.6c0-2.4-2-4.4-4.4-4.4h-4.8
					c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h4.8c4,0,7.2,3.2,7.2,7.2v28.7C59.5,56.3,56.3,59.5,52.3,59.5z M43.6,19.1H28.3
					c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h15.3c0.8,0,1.4,0.6,1.4,1.4S44.4,19.1,43.6,19.1z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M58.1,28.5H13.8c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h44.4c0.8,0,1.4,0.6,1.4,1.4S58.9,28.5,58.1,28.5z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M24.6,20.6c-0.8,0-1.4-0.6-1.4-1.4v-5.4c0-0.8,0.6-1.4,1.4-1.4S26,13,26,13.8v5.4
					C26,19.9,25.3,20.6,24.6,20.6z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M47.3,20.6c-0.8,0-1.4-0.6-1.4-1.4v-5.4c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4v5.4
					C48.7,19.9,48.1,20.6,47.3,20.6z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="st0"
            d="M22.8,40.2c-0.1,0-0.3,0-0.4-0.1c-0.4-0.1-0.7-0.4-0.9-0.7l-2.4-4.5c-0.4-0.7-0.1-1.5,0.6-1.9
					c0.7-0.4,1.5-0.1,1.9,0.6l1.7,3.2l8.4-4.9c0.7-0.4,1.5-0.2,1.9,0.5c0.4,0.7,0.2,1.5-0.5,1.9L23.5,40
					C23.3,40.1,23.1,40.2,22.8,40.2z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M41.8,40.2c-0.1,0-0.3,0-0.4-0.1c-0.4-0.1-0.7-0.4-0.9-0.7l-2.4-4.5c-0.4-0.7-0.1-1.5,0.6-1.9
					c0.7-0.4,1.5-0.1,1.9,0.6l1.7,3.2l8.4-4.9c0.7-0.4,1.5-0.2,1.9,0.5c0.4,0.7,0.2,1.5-0.5,1.9L42.5,40
					C42.3,40.1,42,40.2,41.8,40.2z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M22.8,52.1c-0.1,0-0.3,0-0.4-0.1c-0.4-0.1-0.7-0.4-0.9-0.7l-2.4-4.5c-0.4-0.7-0.1-1.5,0.6-1.9
					c0.7-0.4,1.5-0.1,1.9,0.6l1.7,3.2l8.4-4.9c0.7-0.4,1.5-0.2,1.9,0.5c0.4,0.7,0.2,1.5-0.5,1.9l-9.7,5.7
					C23.3,52,23.1,52.1,22.8,52.1z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M41.8,52.1c-0.1,0-0.3,0-0.4-0.1c-0.4-0.1-0.7-0.4-0.9-0.7l-2.4-4.5c-0.4-0.7-0.1-1.5,0.6-1.9
					c0.7-0.4,1.5-0.1,1.9,0.6l1.7,3.2l8.4-4.9c0.7-0.4,1.5-0.2,1.9,0.5c0.4,0.7,0.2,1.5-0.5,1.9l-9.7,5.7C42.3,52,42,52.1,41.8,52.1
					z"
          />
        </g>
      </g>
    </g>
    <g>
      <path
        class="st0"
        d="M70.9,71.9H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h69.9c0.6,0,1,0.4,1,1v69.9C71.9,71.4,71.4,71.9,70.9,71.9zM2,69.9h67.9V2H2V69.9z"
      />
    </g>
  </g>
</template>

<script>
export default {};
</script>
<style scoped>
.st0 {
  fill: #a8cf45;
}
/* viewBox="0 0 71.9 71.9"  */
</style>
