<template>
  <div class="flex">
    <BigSixIcon @toggleState="AchievesRender()" :isActive="isActive_Achieves">
      <slot>
        <IconBase
          icon-name="Achieves"
          :width="iconWidth"
          :height="iconHeight"
          viewBox="0 0 97.3 75"
          ><Achieves />
        </IconBase>
      </slot>
    </BigSixIcon>

    <BigSixIcon @toggleState="IntellectRender()" :isActive="isActive_Intellect">
      <slot
        ><IconBase icon-name="Intellect" viewBox="0 0 74.2 74.2"
          ><Intellect />
        </IconBase>
      </slot>
    </BigSixIcon>
    <BigSixIcon @toggleState="DevelopsRender()" :isActive="isActive_Develops">
      <slot
        ><IconBase icon-name="Develops" viewBox="0 0 74.2 74.2"
          ><Develops />
        </IconBase>
      </slot>
    </BigSixIcon>
    <BigSixIcon @toggleState="LeadsRender()" :isActive="isActive_Leads">
      <slot>
        <IconBase icon-name="Leads" viewBox="0 0 78.2 74.4"
          ><Leads />
        </IconBase>
      </slot>
    </BigSixIcon>
    <BigSixIcon @toggleState="PresenceRender()" :isActive="isActive_Presence">
      <slot>
        <IconBase icon-name="Presence" viewBox="0 0 71.9 71.9"
          ><Presence />
        </IconBase>
      </slot>
    </BigSixIcon>
    <BigSixIcon @toggleState="CharacterRender()" :isActive="isActive_Character">
      <slot>
        <IconBase icon-name="Character" viewBox="0 0 74.2 74.2"
          ><Character />
        </IconBase>
      </slot>
    </BigSixIcon>
  </div>
  <!--icons -END-->
</template>

<script>
import IconBase from "../assets/images/svgs/IconBase.vue";
import Achieves from "../assets/images/svgs/Achieves.vue";
import Intellect from "../assets/images/svgs/Intellect.vue";
import Develops from "../assets/images/svgs/Develops.vue";
import Leads from "../assets/images/svgs/Leads.vue";
import Presence from "../assets/images/svgs/Presence.vue";
import Character from "../assets/images/svgs/Character.vue";
import BigSixIcon from "../components/BigSixIcon.vue";

export default {
  data() {
    return {
      isActive_Achieves: false,
      isActive_Intellect: true,
      isActive_Develops: false,
      isActive_Leads: false,
      isActive_Presence: true,
      isActive_Character: false,
    };
  },

  components: {
    IconBase,
    Achieves,
    Intellect,
    Develops,
    Leads,
    Presence,
    Character,
    BigSixIcon,
  },

  methods: {
    toggleState: function () {
      this.isActive_Achieves = !this.isActive_Achieves;
      alert("visual change only DB is not updated");
    },
    AchievesRender: function () {
      this.isActive_Achieves = !this.isActive_Achieves;
    },
    IntellectRender: function () {
      this.isActive_Intellect = !this.isActive_Intellect;
    },
    DevelopsRender: function () {
      this.isActive_Develops = !this.isActive_Develops;
    },
    LeadsRender: function () {
      this.isActive_Leads = !this.isActive_Leads;
    },
    PresenceRender: function () {
      this.isActive_Presence = !this.isActive_Presence;
    },
    CharacterRender: function () {
      this.isActive_Character = !this.isActive_Character;
    },
  },
};
</script>

<style>
</style>