 <template>
  <!-- add button -->
  <div>
    <!-- style="color: #db9251; font-weight: bold" -->
    <div
      class="
        flex
        text-sm text-orange
        py-2
        mx-4
        mb-1
        bg-opacity-90
        px-3
        border-2
        bg-navy
        border-orange
        justify-around
      "
    >
      <!-- Expand and Collapse -->
      <p :class="{ hidden: isCollapsed }" :v-model="availableCount">
        <i class="fas fa-plus-circle text-x left-0 p-1 hover:"></i>
        <span class="text-center self-center"> {{ message }}</span>
      </p>
    </div>

    <button @click="toggleClick">
      <!-- <i class="fa fa-align-justify hover:text-white"></i> -->
    </button>
  </div>
</template>
      
<script>
export default {
  props: {
    message: {
      type: String,
      default: "Default Add item",
    },
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggleClick() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>
      
<style>
</style>
      
