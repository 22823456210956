<template>
  <div class="flex mr-10">
    <a href="#" class="self-center p-2 opacity-30 hover:opacity-100"
      ><IconBase icon-name="Autogenerate" color="#fff"
        ><Autogenerate
          class="border"
          icon-name="Autogenerate"
          viewBox="0 0 56.3 59"
        /> </IconBase
    ></a>

    <a href="#" class="self-center p-2 opacity-30 hover:opacity-100"
      ><IconBase icon-name="write" color="#fff"
        ><IconWrite class="border" /> </IconBase
    ></a>
    <a href="#" class="self-center p-2 opacity-30 hover:opacity-100"
      ><IconBase icon-name="write" color="#fff"
        ><IconWrite class="border" /> </IconBase
    ></a>
    <a href="#" class="self-center p-2 opacity-30 hover:opacity-100"
      ><IconBase icon-name="write" color="#fff"
        ><IconWrite class="border" /> </IconBase
    ></a>
    <a href="#" class="self-center p-2 opacity-30 hover:opacity-100"
      ><IconBase icon-name="write" color="#fff"
        ><IconWrite class="border" /> </IconBase
    ></a>
    <a href="#" class="self-center p-2 opacity-30 hover:opacity-100"
      ><IconBase icon-name="write" color="#fff">
        <IconWrite class="border" /> </IconBase
    ></a>
    <a href="#" class="self-center p-2 opacity-30 hover:opacity-100"
      ><IconBase icon-name="write" color="#fff">
        <IconWrite class="border" /> </IconBase
    ></a>
    <a href="#" class="self-center p-2 opacity-30 hover:opacity-100">
      <IconBase icon-name="write" color="#fff">
        <IconWrite class="border" />
      </IconBase>
    </a>
  </div>
  <!--icons -END-->
</template>

<script>
import IconBase from "../assets/images/svgs/IconBase.vue";
import IconWrite from "../assets/images/svgs/IconWrite.vue";
import Autogenerate from "../assets/images/svgs/Autogenerate.vue";

export default {
  data() {
    return {
      intellectSrc: "assets/images/filterItem9.png",
      // intellectSrc: "assets/images/filterItem9.png",
      // intellectSrc: "assets/images/filterItem9.png",
      // intellectSrc: "assets/images/filterItem9.png",
      // intellectSrc: "assets/images/filterItem9.png",
      // intellectSrc: "assets/images/filterItem9.png",
    };
  },
  components: {
    IconBase,
    IconWrite,
    Autogenerate,
  },
};
</script>

<style>
</style>