<template>
  <div class="flex mx-4 flex-col my-2 text-white text-base text-left">
    <!-- Title -->
    <div class="flex justify-between relative" @click="isShow = !isShow">
      <h1
        class="
          border-orange
          rounded-t
          p-1
          px-2
          w-48
          text-orange
          border-2
          bg-opacity-90
          relative
        "
        :class="[
          isClickable && isSelected && 'bg-opacity-100',
          isOrginalStament
            ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
            : 'bg-navy cursor-pointer ',
        ]"
      >
        <div
          class="
            absolute
            top-0
            right-0
            w-4
            h-full
            bg-orange
            cursor-pointer
            text-white
            self-center
            flex flex-col
            items-center
            justify-center
          "
        >
          <i
            class="fas fa-chevron-down transform transition-all"
            :class="{ 'rotate-180 ': !isShow }"
          ></i>
        </div>
        <slot name="header">{{ Title }}</slot>
      </h1>
    </div>
    <!-- Content -->
    <main
      class="
        border-orange
        rounded-b
        py-2
        px-2
        rounded-tr
        border-2
        bg-opacity-90
      "
      style="min-height: 3rem"
      :class="[
        isClickable && isSelected && 'bg-opacity-100',
        isOrginalStament
          ? 'bg-gray-700 text-gray-400 cursor-not-allowed    '
          : 'bg-navy cursor-pointer ',
      ]"
      v-if="isShow"
      @click="isSelected = !isSelected"
    >
      <slot name="body" v-if="isShow">Default Body</slot>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    Title: String,
    isOrginalStament: Boolean,
    isClickable: Boolean,
  },
  data() {
    return {
      isSelected: true,
      isShow: true,
    };
  },
};
</script>

<style>
/* .selected {
  background-color: red !important;
} */
</style>

