<template>
  <g
    ><g>
      <g>
        <path
          class="st0"
          d="M48.2,31.3c-2.5,0-6.1-1.6-8.4-9c-1.4-4.4-2-9.7-2-13c0-0.6,0.5-1.1,1.1-1.1h19.4c0.6,0,1.1,0.5,1.1,1.1
				c0,4.7-1.8,22.4-10.8,21.9C48.4,31.3,48.3,31.3,48.2,31.3C48.2,31.3,48.2,31.3,48.2,31.3z M39.9,10.5c0.1,3.1,0.7,7.5,1.9,11.2
				c1.1,3.4,3.1,7.4,6.3,7.4c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c5.7,0.3,8.2-11.6,8.5-18.6H39.9z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M41.6,26c-0.1,0-0.2,0-0.3,0C30.2,23,30.9,12.3,30.9,12.2c0-0.6,0.5-1,1.1-1l6-0.1c0,0,0,0,0,0
				c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1l-4.9,0.1c0.1,2.4,1.3,8.4,8.8,10.4c0.6,0.2,1,0.8,0.8,1.4
				C42.6,25.6,42.1,26,41.6,26z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M55.6,26c-0.5,0-0.9-0.3-1.1-0.8c-0.2-0.6,0.2-1.2,0.8-1.4c7.5-2,8.7-8,8.8-10.4l-4.9-0.1
				c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1l6,0.1c0.6,0,1.1,0.5,1.1,1c0,0.1,0.7,10.8-10.5,13.8C55.8,26,55.7,26,55.6,26z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M57.1,46.6H39.9c-0.6,0-1.1-0.5-1.1-1.1v-6.2c0-0.6,0.5-1.1,1.1-1.1h17.2c0.6,0,1.1,0.5,1.1,1.1v6.2
				C58.2,46.1,57.7,46.6,57.1,46.6z M41,44.4H56v-4H41V44.4z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M60.2,50.1H36.8c-0.6,0-1.1-0.5-1.1-1.1v-3.5c0-0.6,0.5-1.1,1.1-1.1h23.4c0.6,0,1.1,0.5,1.1,1.1V49
				C61.3,49.6,60.8,50.1,60.2,50.1z M37.9,47.9h21.1v-1.3H37.9V47.9z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M44.2,39.6c-0.3,0-0.7-0.2-0.9-0.4c-0.4-0.5-0.3-1.2,0.2-1.6c1.9-1.5,1.6-6.1,1.4-7.5
				c-0.1-0.6,0.3-1.2,0.9-1.3c0.6-0.1,1.2,0.3,1.3,0.9c0.1,0.7,1.1,7.1-2.2,9.7C44.7,39.6,44.4,39.6,44.2,39.6z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M52.8,39.6c-0.2,0-0.5-0.1-0.7-0.2c-3.3-2.6-2.4-9-2.2-9.7c0.1-0.6,0.7-1,1.3-0.9c0.6,0.1,1,0.7,0.9,1.3
				c-0.3,1.6-0.5,6.1,1.4,7.5c0.5,0.4,0.6,1.1,0.2,1.6C53.5,39.5,53.2,39.6,52.8,39.6z"
        />
      </g>
      <g>
        <polygon
          class="st1"
          points="48.5,14.4 50.4,17.2 53.6,18.2 51.6,20.9 51.7,24.2 48.5,23.1 45.3,24.2 45.4,20.9 43.4,18.2 
				46.6,17.2 			"
        />
      </g>
    </g>
    <g>
      <path
        class="st0"
        d="M48.6,75L0,0h97.3L48.6,75z M3.7,2l45,69.3L93.6,2H3.7z"
      />
    </g>
  </g>
</template>

<script>
export default {};
</script>

<style scoped>
.st0 {
  fill: #7a75b5;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #7a75b5;
}

/* width="97.3px" height="75px"  */
/* enable-background:new 0 0 97.3 75; */
</style>