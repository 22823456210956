<template>
  <div class="flex flex-col justify-center">
    <ContainerLeft Title="Original Statement" isOrginalStament>
      <template #body>
        {{ original }}
      </template>
    </ContainerLeft>

    <ContainerLeft>
      <template #header>
        <div>Modification</div>
      </template>
      <template #body>
        <input
          class="w-full p-4 text-black"
          type="text"
          :placeholder="modification"
        />
        <button
          class="bg-opacity-30 bg-orange w-full rounded text-white p-2"
          :class="{ 'bg-opacity-100': unlockSave }"
        >
          Save
        </button>
      </template>
    </ContainerLeft>

    <div class="row">
      <div class="col-sm-4">
        <ContainerLeft>
          <template #header>
            <div>Section</div>
          </template>
          <template #body>
            <BigSix size="500" />
          </template>
        </ContainerLeft>
      </div>
      <div class="col-sm-4">
        <ContainerLeft>
          <template #header>
            <div>Big 6 Category</div>
          </template>
          <template #body
            ><div>
              <BigSix size="500" />
            </div>
          </template>
        </ContainerLeft>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <ContainerLeft>
          <template #header>
            <div>Suggestions</div>
          </template>
          <template #body>
            <SuggestedList
              @checkboxClick="UpdateAssociatedSuggestionInDataBase"
            />
          </template>
        </ContainerLeft>
      </div>
    </div>

    <button class="bg-orange w-full max-w-lg rounded text-white my-12 mx-4 p-2">
      Save
    </button>
    <Backgrounds :youtubeVideoId="youtubeVideoId" />
  </div>
</template>

<script>
import ContainerLeft from "./ContainerLeft.vue";
import Backgrounds from "./Backgrounds.vue";
import BigSix from "./BigSix.vue";
import SuggestedList from "./SuggestedList.vue";

export default {
  data() {
    return {
      iconHeight: 100,
      iconWidth: 100,
      unlockSave: false,
      youtubeVideoId: "E4ps3M1uXhM",
      original: "statement 2 - ORIGINAL THAT HAS BEEN DUPLICATED FROM",
      modification:
        "statement 2 - MODIFICATION usce eu e, ut sagitti sit  e, ut sagitti sit amet lobortis l non scelerisque ultrices, tellus neque lacinia eros, vitae consequat sem ipsum ut felvitae consequat sem ipsum ut felis. Lo",
      section: "Section Categorey Body",
      suggested: [
        "suggestion 1",
        "suggestion 2",
        "suggestion 3",
        "suggestion 4",
        "suggestion 5",
      ],
      categories: "Big 6 Categorey Body",
      keywords: "Your Modification Body",
      computed: {
        iconSize() {
          return this.iconHeight;
        },
        saveModification() {
          return this.unlockSave;
        },
      },
      method: {
        UpdateAssociatedSuggestionInDataBase: function () {
          alert("Need to set up to UpdateAssociatedSuggestionInDataBase()");
        },
      },
    };
  },

  components: { ContainerLeft, Backgrounds, BigSix, SuggestedList },
};
</script>

    
<style scoped>
.demo {
  font-family: sans-serif;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 20px 30px;
  margin-top: 1em;
  margin-bottom: 40px;
  user-select: none;
  overflow-x: auto;
  display: block;
}
</style>

