<template>
  <div class="video-background bg-black fixed object-left-top">
    <div class="ytplayer2"></div>
    <div class="vidtop-content">
      <div v-if="isNotification" class="vid-info bg-opacity-60">
        <div class="vid-info__alias bg-gray-500">Notifitation</div>
        <div class="vid-info__summary">
          <h1 class="bg-orange text-white text-lg">
            Relevant information can be displayed within a module here.
          </h1>
        </div>
      </div>
    </div>
    <div
      class="
        video-foreground
        absolute
        object-left-top
        w-full
        h-full
        pointer-events-none
      "
    >
      <iframe
        class="absolute object-left-top w-full h-full pointer-events-none"
        :src="websiteUrl"
        allowfullscreen=""
      ></iframe>
      <!-- Youtube Video Options -->
      <!-- 
----------------------------------
----------------------------------
circleScanner: "FVcCIPJO410",
busyHUD: "--ExoErExV0",
codefocus: "RR2EI8EEOOw",
Singer: "_NlQ1sMl1d4", 
hulk: "dKsQP_rEnhk",  (loops require a playlist)

ie:
     <iframe src="https://www.youtube.com/embed/dKsQP_rEnhk?autoplay=1&amp;amp;mute=1&amp;version=3&amp;loop=1&amp;playlist=dKsQP_rEnhk" allowfullscreen=""></iframe> 
----------------------------------
----------------------------------
-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    youtubeVideoId: {
      type: String,
      default: "RR2EI8EEOOw",
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    websiteUrl: function () {
      let websiteUrlFull =
        "https://www.youtube.com/embed/" +
        this.youtubeVideoId +
        "?autoplay=1&amp;mute=1&amp;version=3&amp;loop=1&amp;start=8";
      console.log(websiteUrlFull);
      return websiteUrlFull;
    },
  },
};
</script>
  
<style>
* {
  box-sizing: border-box;
}
.video-background {
  z-index: -99;
}

#vidtop-content {
  top: 0;
  color: #fff;
}
.vid-info {
  position: absolute;
  top: 50vh;
  left: 11vw;
  width: 33%;
  color: #fff;
  padding: 1rem;
  font-family: RobotoCondensed Bold, Trebuchet MS, Helvetica, Arial, sans-serif;
  z-index: 9999;
}

.vid-info__alias {
  font-size: 2.75rem;
  font-weight: 700;
  margin: 0;
  text-shadow: 3px 3px 5px #000;
}
.vid-info__summary {
  text-shadow: 3px 3px 5px #000;
}

/*---prob have to be injected with script--*/
iframe .vid-info {
  top: 0 !important;
  right: 0 !important;
  margin: auto !important;
  width: 300px !important;
}
@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
}
@media all and (max-width: 600px) {
  .vid-info {
    width: 50%;
    padding: 0.5rem;
  }
  .vid-info h1 {
    margin-bottom: 0.2rem;
  }
}
@media all and (max-width: 500px) {
  .vid-info .acronym {
    display: none;
  }
}

* {
  box-sizing: border-box;
}
.video-background {
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#vidtop-content {
  top: 0;
  color: #fff;
}
.vid-info {
  position: absolute;
  top: 50vh;
  left: 11vw;
  width: 33%;
  color: #fff;
  padding: 1rem;
  font-family: RobotoCondensed Bold, Trebuchet MS, Helvetica, Arial, sans-serif;
  z-index: 9999;
}
.vid-info__name {
  font-size: 1rem;
  text-shadow: 3px 3px 5px #000;
}
.vid-info__alias {
  font-size: 2.75rem;
  font-weight: 700;
  margin: 0;
  text-shadow: 3px 3px 5px #000;
}
.vid-info__summary {
  text-shadow: 3px 3px 5px #000;
}

/*---prob have to be injected with script--*/
iframe .vid-info {
  top: 0 !important;
  right: 0 !important;
  margin: auto !important;
  width: 300px !important;
}
@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
}
@media all and (max-width: 600px) {
  .vid-info {
    width: 50%;
    padding: 0.5rem;
  }
  .vid-info h1 {
    margin-bottom: 0.2rem;
  }
}
@media all and (max-width: 500px) {
  .vid-info .acronym {
    display: none;
  }
}

.video-background:after {
  background: linear-gradient(
    45deg,
    rgba(219, 146, 81, 1) 0%,
    rgba(212, 122, 43, 1) 21%,
    rgba(17, 22, 36, 1) 42%,
    rgba(0, 36, 0, 0.87) 65%,
    rgba(219, 146, 81, 0.8) 100%
  );
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  opacity: 0.6;
  left: 0%;
}
</style>