<template>
  <a
    href="#"
    class="
      transition-all
      self-center
      p-2
      opacity-20
      hover:opacity-100
      bg-purple-900 bg-opacity-30 bg-gradient-to-t
      hover:from-purple-600 hover:via-transparent hover:to-transparent
      rounded-sm
      hover:rounded-xl
      transform
      scale-100
      hover:scale-125
      duration-200
    "
    :class="{ 'opacity-100': isActive }"
    @click="toggleState()"
  >
    <slot>
      <IconBase
        icon-name="Achieves"
        :width="iconWidth"
        :height="iconHeight"
        viewBox="0 0 97.3 75"
        ><Achieves />
      </IconBase>
    </slot>
  </a>
</template>

<script>
import IconBase from "../assets/images/svgs/IconBase.vue";
import Achieves from "../assets/images/svgs/Achieves.vue";
export default {
  components: {
    IconBase,
    Achieves,
  },
  props: {
    href: String,
    isActive: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    toggleState() {
      // `this` will refer to the component instance
      this.$emit("toggleState");
    },
    increment() {
      // `this` will refer to the component instance
      this.count++;
    },
  },
};
</script>

<style>
</style>