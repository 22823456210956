<template>
  <div
    class="
      text-left
      flex
      relative
      bg-gradient-to-t
      from-navy
      to-black
      justify-around
      self-center
      items-center
    "
  >
    <div
      class="
        flex
        text-sm text-orange
        border-r border-orange
        pointer-events-none
      "
    >
      <!-- Expand and Collapse -->
      <!-- <i class="fas fa-minus-square text-x absolute left-0 p-1 hover:"></i> -->
      <i class="fas fa-plus-square text-x absolute left-0 p-1 hover:"></i>
      <!-- Contents-->
      <p class="mr-2" :v-model="availableCount">
        <span class="text-white">available:</span> {{ availableCount }}
      </p>
      <p class="mr-2" :v-model="modifiedCount">
        <span class="text-white">modified:</span> {{ modifiedCount }}
      </p>
      <p class="mr-2">
        <span class="text-white">difference:</span> {{ difference }}
      </p>
      <div></div>
    </div>

    <button @click="toggleDraggable()"  title="reorder - Manual">
      <i class="fa fa-align-justify hover:text-white">reorder manual</i>
    </button>
    <button @click="reorderMostRecent()" title="reorder - Most Recent">
      <i class="fa fas fa-sort-amount-up hover:text-white"> Most recent</i>
    </button>
    <button @click="toggleTabs()" title="Show - Tab">
      <i class="fa fa-align-justify hover:text-white">show tab</i>
    </button>

    <button
      class="
        px-1
        rounded
        text-sm
        bg-gradient-to-t
        from-gray-500
        text-white
        opacity-50
        hover:opacity-100 hover:border-gray-900 hover:to-gray-900
      "
      @click="add"
    >
      Blank Template
    </button>
  </div>
</template>

<script>
export default {
  props: {
    // Object with a default value
    // Number with a default value
    availableCount: {
      type: Number,
      default: 440,
    },
    modifiedCount: {
      type: Number,
      default: 121,
    },


  },
  data() {
    return {};
  },

  computed: {
    difference() {
      let a = this.availableCount;
      let b = this.modifiedCount;
      console.log(a);
      console.log(b);
      let c = a - b;
      return c;
    },
  },
  methods: {
    toggleDraggable() {
      this.$emit("toggleDraggable");
    },
    reorderMostRecent() {
      alert("reorderMostRecent");
      this.$emit("reorderMostRecent");
    },
    toggleTabs() {
      this.$emit("toggleTabs");
    },
  },
};
</script>

<style>
</style>