<template>
  <div class="flex flex-col justify-center self-center align-middle h-full">
    <ContainerLeft>
      <template #header>
        <div>Statement</div>
      </template>
      <template #body>
        <input
          class="w-full text-black"
          type="text"
          placeholder="Enter your unique statement"
        />
      </template>
    </ContainerLeft>

    <div class="row">
      <div class="col-sm-4">
        <ContainerLeft>
          <template #header>
            <div>Section</div>
          </template>
          <template #body>
            <BigSix size="500" />
          </template>
        </ContainerLeft>
      </div>
      <div class="col-sm-4">
        <ContainerLeft>
          <template #header>
            <div>Big 6 Category</div>
          </template>
          <template #body
            ><div>
              <BigSix size="500" />
            </div>
          </template>
        </ContainerLeft>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <ContainerLeft>
          <template #header>
            <div>Suggestions</div>
          </template>
          <template #body>
            <SuggestedList
              @checkboxClick="UpdateAssociatedSuggestionInDataBase"
            />
          </template>
        </ContainerLeft>
      </div>
    </div>
    <button
      v-on:click="updateDB"
      class="bg-orange w-full max-w-lg rounded text-white my-12 mx-4 p-2"
    >
      Save New Statement
    </button>
    <Backgrounds />
  </div>
</template>

<script>
import ContainerLeft from "../components/ContainerLeft.vue";
import Backgrounds from "../components/Backgrounds.vue";
import BigSix from "../components/BigSix.vue";
import SuggestedList from "../components/SuggestedList.vue";

export default {
  data() {
    return {
      iconHeight: 100,
      iconWidth: 100,
      original: "statement 2 - ORIGINAL THAT HAS BEEN DUPLICATED FROM",
      modification:
        "statement 2 - MODIFICATION usce eu e, ut sagitti sit  e, ut sagitti sit amet lobortis l non scelerisque ultrices, tellus neque lacinia eros, vitae consequat sem ipsum ut felvitae consequat sem ipsum ut felis. Lo",
      section: "Section Categorey Body",

      categories: "Big 6 Categorey Body",
      keywords: "Your Modification Body",
      computed: {
        iconSize() {
          return this.iconHeight;
        },
      },
    };
  },

  methods: {
    updateDB: function () {
      alert("update the database with all changes");
    },
  },

  components: { ContainerLeft, Backgrounds, BigSix, SuggestedList },
};
</script>

    
<style scoped>
.demo {
  font-family: sans-serif;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 20px 30px;
  margin-top: 1em;
  margin-bottom: 40px;
  user-select: none;
  overflow-x: auto;
  display: block;
}
</style>

