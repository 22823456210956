<template>
  <UserCrud class="mt-3" />
  <AddItemButton message="Add New Soldier" />
  <Backgrounds :youtubeVideoId="youtubeVideoId" />
</template>

<script>
import AddItemButton from "./AddItemButton.vue";
import Backgrounds from "../components/Backgrounds.vue";
import UserCrud from "./UserCrud.vue";

export default {
  data() {
    return {
      youtubeVideoId: "bWXIJSiagBY",
    };
  },
  components: { AddItemButton, UserCrud, Backgrounds },
};
</script>

<style>
</style>