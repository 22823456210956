<template>
  <div>
    <Detail class="flex flex-col h-screen overflow-hidden" />
    <Backgrounds :youtubeVideoId="youtubeVideoId" />
  </div>
</template>

<script>
import Backgrounds from "../components/Backgrounds";
import Detail from "../components/Detail.vue";
export default {
  data() {
    return {
      youtubeVideoId: "E4ps3M1uXhM",
    };
  },
  components: { Backgrounds, Detail },
};
</script>

<style>
</style>