<template>
  <div class="m-auto h-screen flex flex-col justify-content">
    <Login class="m-auto" />
  </div>
  <div class="embed-onet-ip"></div>
  <Backgrounds :youtubeVideoId="youtubeVideoId" />
  <!-- ----------------------------------------------------------------------------------------------------- -->
</template>

<script>
import Login from "../components/Login.vue";
import Backgrounds from "../components/Backgrounds.vue";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";

export default {
  setup() {
    onMounted(function () {
      // axios.get("https://reqres.in/api/users");

      axios.get(
        "https://services.onetcenter.org/ws/?client=wilsonvo217_wpengine"
      );
    });
  },

  components: {
    Login,
    Backgrounds,
  },
  data() {
    return {
      youtubeVideoId: "--ExoErExV0",
    };
  },
};
</script>

<style lang="scss">
</style>
