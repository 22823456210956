<template>
  <div
    class="
      max-w-md
      p-3
      border-2 border-orange
      flex flex-col
      bg-gray-700 bg-opacity-90
      rounded-2xl
    "
  >
    <div class="relative my-6">
      <div class="text-4xl text-white pb-2">ARMIFY</div>
      <img
        class="bottom-1 w-20 left-9 self-center absolute"
        src="../assets/images/armifyUnderline.png"
      />
    </div>

    <form class="" method="POST" action="">
      <div class="bg-orange rounded-l-md justify-center">
        <i class="fa fa-user fa-lg self-center py-2 p-3 opacity-75"></i>
        <input
          class="hover:border-orange border-2 py-2 border-white"
          type="text"
          name="username"
          value=""
          id="id_username"
          required=""
          maxlength="64"
          placeholder="Username"
        />
      </div>
      <div class="bg-orange justify-center rounded-l-md my-3">
        <i class="fa fa-user fa-lg self-center py-2 p-3 opacity-75"></i>
        <input
          class="hover:border-orange border-2 py-2 border-white"
          type="text"
          name="username"
          value=""
          id="id_username"
          required=""
          maxlength="64"
          placeholder="Password"
        />
      </div>

      <div class="flex justify-between text-white">
        <div class=" flex align-middle">
          <input
            class="border flex align-middle self-center"
            type="checkbox"
            id="remember"
            name="remember"
            value="Bike"
          /><label for="remember">
            <span class="self-center text-white px-2 text-xs"
              >REMEMBER ME</span
            ></label
          >
        </div>
        <a
          href="/reset-password"
          class="flex text-xs h-full self-center hover:text-orange"
        >
          Forget Password?
        </a>
      </div>
      <button type="button" class="btn btn-primary text-2xl w-100">
        LOGIN
      </button>
    </form>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>