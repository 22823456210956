<template>
  <div
    class="w-full bg-gradient-to-r from-gray-800 to-black text-white px-4 py-2"
  >
    <div class="flex">
      <div class="flex justify-between mX- w-full border">
        <SubCatagories />
        <BigSix />
      </div>
      <div class="flex border">
        <input type="text" name="" id="" />
        <i class="fas fa-search p-1"></i>
      </div>
    </div>
  </div>
</template>

<script>
import BigSix from "../components/BigSix.vue";
import SubCatagories from "../components/SubCatagories.vue";
export default {
  data() {
    return {
      navType: "wordsmith",
      isMenuOpen: false,
      links: [
        {
          title: "",
          url: " Original src title",
          pageUrl: "/pageurl",
          src_icon: "./../assets/images/Element1.png",
          isSelected: false,
        },
        {
          title: " ",
          url: " Original src title",
          pageUrl: "/pageurl",
          src_icon: "./../assets/images/Element1.png",
          isSelected: false,
        },
      ],
    };
  },
  components: { BigSix, SubCatagories },
};
</script>

<style>
</style>
