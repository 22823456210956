<template>
  <div class="relative">
    <FilterBar />

    <ExpandableBar
      @toggleDraggable="toggleDraggable()"
      @toggleTabs="toggleTabs()"
    />

    <Bullet :enabled="this.enabled" />
    <Tabs v-if="showTabs" Class="absolute left-0 bottom-0 w-full" />
    <Backgrounds :youtubeVideoId="youtubeVideoId" />
  </div>
</template>

<script>
import Backgrounds from "../components/Backgrounds";
import Bullet from "../components/Bullet.vue";
import Tabs from "../components/Tabs.vue";
import ExpandableBar from "../components/ExpandableBar.vue";
import FilterBar from "../components/FilterBar.vue";
export default {
  components: { Backgrounds, Bullet, Tabs, ExpandableBar, FilterBar },
  data() {
    return {
      showTabs: true,
      enabled: true,
      youtubeVideoId: "U6A7Iv0RbdA",
    };
  },
  methods: {
    toggleDraggable: function () {
      this.enabled = !this.enabled;
    },
    toggleTabs: function () {
      this.showTabs = !this.showTabs;
    },
  },
};
</script>

<style>
</style>