<template>
  <div>
    <ul>
      <li
        v-for="(suggestion, index) in suggestedList"
        :key="`suggested-${index}`"
      >
        <div
          class="flex bg-opacity-50 justify-between py-1 px-2"
          :class="{
            'bg-grey-600': index % 2 === 0,
            'bg-yellow-600': index % 2 !== 0,
          }"
        >
          <label class="form-check-label" for="disabled">
            {{ suggestion }} {{ index }}</label
          >
          <input
            type="checkbox"
            class="flex self-center align-middle"
            @click="checkboxClick()"
            :checked="isChecked"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isChecked: false,
      suggestedList: [
        "ffsdf sadf sadf asdfsd fsd sd",
        "ddf sdf sadf sdaf sdf asdfd ss",
        "hU nt hhOthu ",
        "thT ThaoThoa ThOthuao ",
        "hoeuoasntout   hutonggoatho oauhet At",
      ],
    };
  },
  methods: {
    checkboxClick: function () {
      this.emit("checkboxClick");
      this.isChecked = !this.isChecked;
    },
  },
};
</script>

<style>
</style>