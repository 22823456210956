import { createRouter, createWebHistory } from "vue-router";
import Home from "./pages/Home";
import SoldierList from "./pages/SoldierList";
import SoldierDetail from "./pages/SoldierDetail";
import StatementList from "./pages/StatementList";
import StatementDetail from "./pages/StatementDetail";
import Export from "./pages/Export";
import Bullet from "./components/Bullet";
import ExpandableBar from "./components/ExpandableBar";
import Detail from "./components/Detail";
import Tabs from "./components/Tabs";
import Handle from "./components/Handle";
import StatementCustom from "./pages/StatementCustom";

const routes = [
  { path: "/", component: Home },
  { path: "/Soldier-List", component: SoldierList },
  { path: "/soldier-detail", component: SoldierDetail },
  { path: "/statement-list", component: StatementList },
  { path: "/statement-detail", component: StatementDetail },
  { path: "/statement-Custom", component: StatementCustom },
  { path: "/Bullet", component: Bullet },
  { path: "/ExpandableBar", component: ExpandableBar },
  { path: "/Detail", component: Detail },
  { path: "/Export", component: Export },
  { path: "/Tabs", component: Tabs },
  { path: "/Handle", component: Handle },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
