<template>
  <g>
    <g>
      <g>
        <g>
          <path
            class="st0"
            d="M38.1,48.9c0,3.3,1.6,6,3.7,8.2H14.7c0-1.8,0-8.8,0-10.6c0-1.8,0.4-3.6,1.1-5.3c2.2-5.3,6.1-8.6,11.7-9.9
					c0.2-0.1,0.6,0,0.8,0.1c5.4,3.4,10.8,3.3,16.3,0.1c0.3-0.2,0.8-0.3,1.2-0.2c1.3,0.3,2.5,0.7,3.6,1.3c-2.1,1.7-5.8,3.1-8.6,3.1
					h-2.9V48.9z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M36.7,31c-6.4,0-11.6-5.1-11.6-11.5c0-6.3,5.1-11.6,11.4-11.6c6.3,0,11.7,5.3,11.7,11.5
					C48.2,25.8,43,31,36.7,31z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="st0"
            d="M41,38.6v10.3c0,7,11.2,11.6,11.2,11.6s11.2-4.6,11.2-11.6V38.2c-3.6,0-9-1.5-11.2-4
					C49.6,36.8,44.6,38.6,41,38.6z"
          />
        </g>
      </g>
      <g>
        <polygon
          class="st1"
          points="54.8,43.6 60.6,44.2 56.5,48.5 57.7,54.2 52.4,51.6 47.3,54.5 48.1,48.7 43.8,44.7 49.6,43.7 
				52,38.4 			"
        />
      </g>
    </g>
    <g>
      <path
        class="st0"
        d="M37.1,74.2C16.6,74.2,0,57.5,0,37.1C0,16.6,16.6,0,37.1,0c20.4,0,37.1,16.6,37.1,37.1
			C74.2,57.5,57.5,74.2,37.1,74.2z M37.1,2C17.7,2,2,17.7,2,37.1s15.7,35.1,35.1,35.1c19.3,0,35.1-15.7,35.1-35.1S56.4,2,37.1,2z"
      />
    </g>
  </g>
</template>

<script>
export default {};
</script>

<style scoped>
.st0 {
  fill: #08d3e9;
}
.st1 {
  fill: #ffffff;
}
/* viewBox="0 0 74.2 74.2"  */
</style>

