<template>
  <div class="flex ml-8 flex-col">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <div
            class="btn-group-vertical buttons"
            role="group"
            aria-label="Basic example"
          ></div>
        </div>
      </div>

      <div class="col-12">
        <draggable
          v-model:list="DB_bullets"
          :disabled="!enabled"
          item-key="statement"
          class=""
          ghost-class="ghost"
          :move="checkMove"
          @start="dragging = true"
          @end="dragging = false"
        >
          <template #item="{ element }">
            <div
              :isClickable="true"
              class="
                relative
                border-orange border-2
                p-2
                pb-0
                m-1
                rounded-xl
                text-white text-left
              "
              :class="[
                { 'not-draggable': !enabled },
                element.isClickable && element.isSelected && 'bg-opacity-100',
                element.isModified
                  ? 'bg-gray-700 cursor-not-allowed opacity-50'
                  : 'bg-navy cursor-pointer bg-opacity-90',
              ]"
            >
              <div class="row">
                <div class="col-md-11 col-sm-10">
                  <div class="flex">
                    <div
                      class="
                        absolute
                        flex
                        border
                        h-full
                        top-0
                        -left-5
                        bg-gray-400
                        rounded-l
                        px-1
                        items-center
                      "
                      :class="[
                        { 'not-draggable': !enabled },
                        { 'hidden  ': !enabled },
                      ]"
                    >
                      <i class="fa fa-align-justify"></i>
                    </div>
                    {{ element.statement }}
                  </div>
                </div>
                <div class="col-md-1 col-sm-2">
                  <a class="" v-bind:href="'/elements' + element.pageUrl">
                    <img src="../assets/images/editBtn.png" class="w-5 h-5" />
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-6 col-12">
                  <!-- Icons -->
                  <div class="flex flex-wrap justify-between">
                    <SubCatagories />
                    <Bigsix />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>

    <button class="hidden" @click="removeBullet(index)">x</button>
  </div>
</template>

<script>
import draggable from "vuedraggable";
let id = 1;
import SubCatagories from "../components/SubCatagories.vue";
import Bigsix from "../components/BigSix.vue";
export default {
  props: {
    enabled: Boolean,
  },
  data() {
    return {
      reorderMostRecent: true,
      isActive: true,
      showTabs: true,
      list: [
        { name: "John", id: 0 },
        { name: "Joao", id: 1 },
        { name: "Jean", id: 2 },
      ],
      dragging: false,
      newbullet: "",
      DB_bullets: [
        {
          statement:
            "statement 1     ectetur adipisorper viverra. Dllus neque lacinia eros, vitae consequat sem ipsum ut felis. Lo",
          catagories: ["Intellect", "Achieves"],
          src_statement: " Original src statement",
          pageUrl: "/pageurl",
          isModified: false,
          isSelected: false,
        },
        {
          statement: "statement 2 - ORIGINAL THAT HAS BEEN DUPLICATED FROM",
          pageUrl: "/pageurl",
          catagories: ["Intellect", "Achieves"],
          src_statement: " Original src statement",
          isModified: true,
          isSelected: false,
        },
        {
          statement:
            "statement 2    usce eu e, ut sagitti sit amet lobortis l non scelerisque ultrices, tellus neque lacinia eros, vitae consequat sem ipsum ut felis. Lo",
          catagories: ["Intellect", "Achieves"],
          src_statement: " Original src statement",
          pageUrl: "/pageurl",
          isModified: false,
          isSelected: false,
        },
        {
          statement:
            "statement 3  lor sit amet, consectetsce eu efficitur torte vel velit ullamcorper viverra. Donec ullamcorper, est non scelerisque ulte consequat sem ipsum ut felis. Lo",
          catagories: ["Intellect", "Achieves"],
          src_statement: " Original src statement",
          pageUrl: "/pageurl",
          isModified: false,
          isSelected: false,
        },
        {
          statement:
            "statement 4 Lorem ipsum lobortis loremuat sem ipsum ut felis. Lo",
          catagories: ["Intellect", "Achieves"],
          src_statement: " Original src statement",
          pageUrl: "/pageurl",
          isModified: false,
          isSelected: false,
        },
        {
          statement:
            "statement 5  is a very long to line sentance on we can see wrapper will prob want to set a character limit for what is viewablestatement 5  is a very long to line sentance on we can see wrapper will prob want to set a character limit for what is viewablestatement 5  is a very long to line sentance on we can see wrapper will prob want to set a character limit for what is viewable",
          catagories: ["Intellect", "Achieves"],
          src_statement: " Original src statement",
          pageUrl: "/pageurl",
          isModified: true,
          isSelected: false,
        },
        {
          statement:
            "statement 4 Lorem ipsum lobortis loremuat sem ipsum ut felis. Lo",
          catagories: ["Intellect", "Achieves"],
          src_statement: " Original src statement",
          pageUrl: "/pageurl",
          isModified: false,
          isSelected: false,
        },
        {
          statement:
            "statement 5  is a very long to line sentance on we can see wrapper will prob want to set a character limit for what is viewablestatement 5  is a very long to line sentance on we can see wrapper will prob want to set a character limit for what is viewablestatement 5  is a very long to line sentance on we can see wrapper will prob want to set a character limit for what is viewable",
          catagories: ["Intellect", "Achieves"],
          src_statement: " Original src statement",
          pageUrl: "/pageurl",
          isModified: true,
          isSelected: false,
        },
        {
          statement:
            "statement 4 Lorem ipsum lobortis loremuat sem ipsum ut felis. Lo",
          catagories: ["Intellect", "Achieves"],
          src_statement: " Original src statement",
          pageUrl: "/pageurl",
          isModified: false,
          isSelected: false,
        },
        {
          statement:
            "statement 5  is a very long to line sentance on we can see wrapper will prob want to set a character limit for what is viewablestatement 5  is a very long to line sentance on we can see wrapper will prob want to set a character limit for what is viewablestatement 5  is a very long to line sentance on we can see wrapper will prob want to set a character limit for what is viewable",
          catagories: ["Intellect", "Achieves"],
          src_statement: " Original src statement",
          pageUrl: "/pageurl",
          isModified: true,
          isSelected: false,
        },
      ],
    };
  },
  computed: {},
  methods: {
    add: function () {
      this.list.push({ name: "Juan " + id, id: id++ });
    },
    replace: function () {
      this.list = [{ name: "Edgard", id: id++ }];
    },
    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    toggleState() {
      this.isActive = !this.isActive;
    },
    ModifiedBulletsCount() {
      let testArray = this.DB_bullets;
      let testArraySpread = testArray.filter((word) => word.isModified);
      console.log(testArray);
      console.log(testArraySpread);
      return testArraySpread.length;
    },
    updateDB() {
      //make a call to update all of the database or api call
    },
  },
  components: { Bigsix, SubCatagories, draggable },
};
</script>

<style scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.not-draggable {
  cursor: no-drop;
}
</style>