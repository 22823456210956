<template>
  <div>
    <div class="flex justify-around mt-4">
      <div
        class="
          border-2
          flex
          p-8
          bg-black bg-opacity-70
          border-green-900
          text-green-900
        "
      >
        <a
          href="#"
          class="
            transition-all
            self-center
            p-2
            opacity-20
            hover:opacity-100
            bg-purple-900 bg-opacity-30 bg-gradient-to-t
            hover:from-purple-600 hover:via-transparent hover:to-transparent
            rounded-sm
            hover:rounded-xl
            transform
            scale-100
            hover:scale-125
            duration-200
          "
          ><IconBase icon-name="Achieves" viewBox="0 0 97.3 75"
            ><Achieves /> </IconBase
        ></a>
        <span> Widget</span>
      </div>
      <div
        class="
          border-2
          flex
          p-8
          bg-black bg-opacity-70
          border-green-900
          text-green-900
        "
      >
        <a
          href="#"
          class="
            transition-all
            self-center
            p-2
            opacity-20
            hover:opacity-100
            bg-purple-900 bg-opacity-30 bg-gradient-to-t
            hover:from-purple-600 hover:via-transparent hover:to-transparent
            rounded-sm
            hover:rounded-xl
            transform
            scale-100
            hover:scale-125
            duration-200
          "
          ><IconBase icon-name="Achieves" viewBox="0 0 97.3 75"
            ><Achieves /> </IconBase
        ></a>
        <span> Widget</span>
      </div>
      <div
        class="
          border-2
          flex
          p-8
          bg-black bg-opacity-70
          border-green-900
          text-green-900
        "
      >
        <a
          href="#"
          class="
            transition-all
            self-center
            p-2
            opacity-20
            hover:opacity-100
            bg-purple-900 bg-opacity-30 bg-gradient-to-t
            hover:from-purple-600 hover:via-transparent hover:to-transparent
            rounded-sm
            hover:rounded-xl
            transform
            scale-100
            hover:scale-125
            duration-200
          "
          ><IconBase icon-name="Achieves" viewBox="0 0 97.3 75"
            ><Achieves /> </IconBase
        ></a>
        <span> Widget</span>
      </div>
      <div
        class="
          border-2
          flex
          p-8
          bg-black bg-opacity-70
          border-green-900
          text-green-900
        "
      >
        <a
          href="#"
          class="
            transition-all
            self-center
            p-2
            opacity-20
            hover:opacity-100
            bg-purple-900 bg-opacity-30 bg-gradient-to-t
            hover:from-purple-600 hover:via-transparent hover:to-transparent
            rounded-sm
            hover:rounded-xl
            transform
            scale-100
            hover:scale-125
            duration-200
          "
          ><IconBase icon-name="Achieves" viewBox="0 0 97.3 75"
            ><Achieves /> </IconBase
        ></a>
        <span> Widget</span>
      </div>
    </div>
    <div></div>

    <Carosal />
  </div>
</template>

<script>
import IconBase from "../../assets/images/svgs/IconBase.vue";
import Achieves from "../../assets/images/svgs/Achieves.vue";
import Carosal from "../Carosal.vue";

export default {
  components: {
    IconBase,
    Achieves,
    Carosal,
  },
};
</script>

<style>
</style>