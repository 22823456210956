<template>
  <g>
    <g>
      <g>
        <g>
          <path
            class="st0"
            d="M38.7,33.4c-0.6,0.4-0.7,0.8-0.7,1.4c0,0.6,0,1.2,0,1.7c0,0.6-0.3,1-1,1c-0.7,0-1-0.3-1.1-1
					c0-0.4,0-0.7-0.1-1.1c-0.2,0-0.3,0-0.3,0.1c-1.2,1.2-2.7,1.1-4.2,1c-0.9,0-1.8,0-2.8,0c0.1,1,0.1,1.9,0.2,2.8
					c0.1,1.4,0.2,2.8,0.3,4.2c0,0.7,0.2,1,0.9,1.2c1.2,0.3,2.4,0.6,3.5,1.1c0.5,0.2,1,0.6,1.3,1c1,1.7,1.9,3.5,2.8,5.2
					c0.5,1,0.2,2-0.6,2.4c-0.9,0.5-1.8,0.1-2.4-0.8c-0.8-1.4-1.5-2.8-2.3-4.2c-0.2-0.3-0.5-0.6-0.9-0.8c-1-0.4-2-0.6-3.1-1
					c0.1,1.4,0.2,2.7,0.4,4c0.1,0.8,0,1.5-0.5,2.2c-0.9,1.6-1.8,3.2-2.7,4.8c-0.6,1-1.5,1.4-2.4,0.9c-0.9-0.5-1.1-1.5-0.6-2.5
					c0.8-1.5,1.6-3,2.4-4.5c0.2-0.3,0.2-0.8,0.2-1.2c0-0.7-0.1-1.4-0.2-2.1c0-0.8-0.2-1.5-0.8-2.1c-0.4-0.4-0.6-1.2-0.6-1.8
					c-0.2-2.2-0.4-4.5-0.5-6.8c-0.9,0.7-1.4,1.2-1.2,2.3c0.2,1.1,0.2,2.2,0.2,3.3c0,0.9-0.5,1.6-1.4,1.7c-0.8,0.1-1.6-0.5-1.6-1.4
					c-0.2-1.8-0.3-3.6-0.4-5.4c0-0.3,0.2-0.6,0.4-0.8c1-1.2,2.1-2.3,3.2-3.4c0.2-0.2,0.3-0.4,0.4-0.6c0.3-0.8,0.5-1.6,0.9-2.5
					c-0.9-0.6-1.6-1.5-1.7-2.9c0-1,0.3-1.8,1-2.5c1.3-1.4,3.5-1.4,4.9,0c1.5,1.5,1.3,3.6-0.6,5.4c0.3,0.5,0.6,0.9,1,1.4
					c0.1,0.1,0.4,0.2,0.6,0.2c1.3,0,2.5,0.1,3.8,0c0.4,0,0.8-0.3,1.3-0.5c1.8-0.8,2.2-2,2.2-3.9c-0.2-4.6-0.1-9.3-0.1-13.9
					c0-0.3,0-0.6,0-0.9c0.1-0.6,0.4-1,1-1c0.6,0,0.9,0.3,1,1c0,0.2,0,0.4,0.1,0.5v7.3c0,0.3-0.1,0.6-0.1,0.9c0,2.2,0,4.5,0,6.7
					c0,0.5,0.1,0.8,0.6,1C39.7,31.4,39.7,32.7,38.7,33.4z"
          />
        </g>
      </g>
      <g>
        <path
          class="st0"
          d="M20.3,62.7C20.4,62.7,20.4,62.7,20.3,62.7c0.5-0.1,0.9-0.1,1.3-0.2c2.2-0.3,4.5-0.7,6.7-1
				c2.6-0.4,5.3-0.8,7.9-1.2c1.2-0.2,2-1.1,2.4-1.6c3.3-4.3,6.6-8.6,10-12.9l6.5-8.4l-2.9-2.2l10.5-4.6L61.3,42l-2.7-2l-1.4,1.6
				l-0.1,0.1c-0.2,0.2-0.3,0.3-0.5,0.5L54,45.8c-4.7,6.1-9.3,12.1-14,18.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0
				c-6.8,1-13.6,2-19.5,2.9V62.7z"
        />
      </g>
    </g>
    <g>
      <path
        class="st0"
        d="M63.3,74.4H14.9L0,28.4L39.1,0l39.1,28.4L63.3,74.4z M16.4,72.4h45.4l14-43.2L39.1,2.5L2.4,29.2L16.4,72.4z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M26.8,22.1H36v-7.3c-0.1,0.6-0.3,0.6-1.3,0.6h-7.9c0.5,0.7,1,1.4,1.4,2c0.9,1.3,0.9,1.3,0,2.6
			C27.8,20.7,27.3,21.4,26.8,22.1z"
      />
    </g>
  </g>
</template>

<script>
export default {};
</script>

<style scoped>
.st0 {
  fill: #f16467;
}
/* viewBox="0 0 78.2 74.4"  */
</style>
	
