<template>
  <div class="flex flex-col">
    <!-- Top -->
    <div class="text-white bg-navy flex border-orange border-t-2 rounded-tl-lg">
      <button
        class="
          py-1
          px-2
          rounded-tl-lg rounded-b-none
          bg-gray-800
          opacity-30
          hover:border-orange
          hover:opacity-100
          hover:bg-orange
          hover:bg-opacity-60
        "
      >
        Intellect
      </button>
      <button
        class="
          py-1
          px-2
          rounded-tl-lg rounded-b-none
          bg-gray-800
          opacity-30
          hover:border-orange
          hover:opacity-100
          hover:bg-orange
          hover:bg-opacity-60
        "
      >
        Profile
      </button>
      <button
        class="
          py-1
          px-2
          rounded-tl-lg rounded-b-none
          bg-gray-800
          opacity-30
          hover:border-orange
          hover:opacity-100
          hover:bg-orange
          hover:bg-opacity-60
        "
      >
        Leads
      </button>
      <button
        class="
          py-1
          px-2
          rounded-tl-lg rounded-b-none
          bg-gray-800
          opacity-30
          hover:border-orange
          hover:opacity-100
          hover:bg-orange
          hover:bg-opacity-60
        "
      >
        Presence
      </button>
      <button
        class="
          py-1
          px-2
          rounded-tl-lg rounded-b-none
          bg-gray-800
          opacity-30
          hover:border-orange
          hover:opacity-100
          hover:bg-orange
          hover:bg-opacity-60
        "
      >
        Character
      </button>
      <button
        class="
          py-1
          px-2
          rounded-tl-lg rounded-b-none
          bg-gray-800
          opacity-30
          hover:border-orange
          hover:opacity-100
          hover:bg-orange
          hover:bg-opacity-60
        "
      >
        Develops
      </button>
      <button
        class="
          py-1
          px-2
          rounded-b-none
          bg-gray-800
          opacity-30
          hover:border-orange
          hover:opacity-100
          hover:bg-orange
          hover:bg-opacity-60
        "
      >
        +
      </button>
    </div>
    <!-- bottom -->
    <div class="bg-navy p-2">
      <textarea name="" id="" class="flex w-full"></textarea>
    </div>
    <!-- collapasable history -->
    <div
      class="
        block
        bg-gradient-to-t
        from-gray-600
        to-gray-400
        text-white
        py-1
        text-left
        p-2
      "
    >
      {{ history1 }}
      <br />
      {{ history2 }}
      <br />
      {{ history3 }}
    </div>
    <p class="text-left hidden">
      -change color if started typing and changes have not been saved to db yet
      on current tab.<br />
      - each tab could have its own color for its current save statements<br />
      - could show history of saved states
    </p>
  </div>
</template>

<script>
// import { ref } from "vue";

export default {
  props: {
    // Title: String,
    // isOrginalStament: Boolean,
    // isClickable: Boolean,
  },
  data() {
    return {
      history1: "historical comment 1",
      history2: "historical comment 2",
      history3: "historical comment 3",
      // isSelected: false,
      // isShow: true,
    };
  },
};
</script>

<style>
/* .selected {
  background-color: red !important;
} */
</style>

