<template>
  <g>
    <g>
      <path
        d="M38.1,17.8H13.2c-1.1,0-2-0.9-2-2s0.9-2,2-2h24.9c1.1,0,2,0.9,2,2S39.2,17.8,38.1,17.8z"
      />
    </g>
    <g>
      <path
        d="M38.1,30.3H13.2c-1.1,0-2-0.9-2-2s0.9-2,2-2h24.9c1.1,0,2,0.9,2,2S39.2,30.3,38.1,30.3z"
      />
    </g>
    <g>
      <path
        d="M38.1,5.2H13.2c-1.1,0-2-0.9-2-2s0.9-2,2-2h24.9c1.1,0,2,0.9,2,2S39.2,5.2,38.1,5.2z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M3.2,0c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2C1.4,6.3,0,4.9,0,3.2C0,1.4,1.4,0,3.2,0"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M3.2,12.6c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2C0,14,1.4,12.6,3.2,12.6"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M3.2,25.1c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2C1.4,31.4,0,30,0,28.3C0,26.5,1.4,25.1,3.2,25.1"
      />
    </g>
  </g>
</template>

<script>
export default {};
</script>

<style scoped>
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
/* viewBox="0 0 40.1 31.4" */
</style>
	
