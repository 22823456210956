<template>
  <Dashboard />
  <Carosal />
  <Backgrounds :youtubeVideoId="youtubeVideoId" />
</template>

<script>
import Dashboard from "../components/dashboard/Dashboard.vue";
import Backgrounds from "../components/Backgrounds.vue";

export default {
  data() {
    return {
      youtubeVideoId: "Yv-jiMG-BqI",
    };
  },
  components: { Backgrounds, Dashboard },
};
</script>

<style>
</style>